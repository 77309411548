<template>
<div class="row child-component" style="margin-top: 15px">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
        <div class="d-block row col-lg-8 mx-auto">
            <div class="fieldborder" style="margin-top: -4px">
                <label for="axlocatoin" class="lbltxt">INTERCOMPANY AGENCY NAME</label>
                <b-form-input autocomplete="off" id="icagencyname" class="omniipclass" v-model="IC.icagencyname" placeholder="Enter InterCompany Agency Name" :maxlength="100"></b-form-input>
            </div>

            <div class="fieldborder" style="margin-top: -4px">
                <label for="axlocatoin" class="lbltxt">HFM Code</label>
                <b-form-input autocomplete="off" @blur="CheckHFMCodeExist()" id="ichfmcode" class="omniipclass" v-model="IC.hfmcode" placeholder="Enter HFM Code"  v-bind:class="{
              errorborder: IsCodeExist,
              noterrorborder: !IsCodeExist,
            }"></b-form-input>
                <p v-if="IsCodeExist" class="error-message-format">
                    This HFM code already exists in AX. Please proceed with an alternate
                    one.
                </p>
            </div>

            <div class="fieldborder" style="margin-top: -4px">
                <OmniSelect v-model="IC.agencynwregion" :options="NetworkRegion" name="INTERCOMPANY AGENCY NETWORK/REGION" placeholder="Enter or Select InterCompany Agency Network/Region" :showSearch="true" />
            </div>

            <div class="fieldborder" style="margin-top: -22px">
                <OmniSelect v-model="IC.country" :options="InterAgencyCountryList" @input="Countrychange" name="COUNTRY" placeholder="Enter or Select Country" :allowFiltering="true" :showSearch="true" multiple="true" />
            </div>

            <div class="fieldborder"  style="margin-top: -22px">
                <label for="clientbillingstreetaddress" v-if="true" class="lbltxt">INTERCOMPANY AGENCY STREET ADDRESS</label>
                <b-form-input  autocomplete="off" id="BillingAd1" class="omniipclass" v-model="IC.billingstreetaddressline1" placeholder="Enter Address Line 1" :maxlength="26"></b-form-input>
            </div>

            <div class="fieldborder"  style="margin-top: -4px">
                <b-form-input autocomplete="off" id="BillingAd2" class="omniipclass" v-if="true" v-model="IC.billingstreetaddressline2" :maxlength="26" placeholder="Enter Address Line 2"></b-form-input>
            </div>

            <!-- <div class="fieldborder" v-if="!IsCountryUSA" v-bind:class="{
            withtopmargin: IsCountryUSA,
            withnottopmargin: !IsCountryUSA,
          }" style="margin-top: -22px">
                <label for="axlocatoin" class="lbltxt">INTERCOMPANY AGENCY STREET ADDRESS</label>
                <b-form-input autocomplete="off" id="icagencystreetaddress" class="omniipclass" v-model="IC.streetaddress" :maxlength="26" placeholder="Enter InterCompany Agency Street Address"></b-form-input>
            </div> -->

            <div class="fieldborder"  style="margin-top: -8px">
                <label for="city" class="lbltxt" >CITY</label>
                <b-form-input autocomplete="off" id="billingCity" class="omniipclass"  v-model="IC.city" placeholder="Enter City" :maxlength="30"></b-form-input>
            </div>

            <div style="margin-top: -8px" class="row fieldborder" >
                <div class="col-md-8" v-if="IsCountryUSA || IsCountryCanada">
                    <OmniSelect class="pt-0 omniindexclass omniindexclass"  placeholder="Select State" v-bind:name="IsCountryUSA? 'State':'Province'" :showLabel="true" label="STATE"  :showSearch="true" v-model="IC.state"  v-bind:options="IsCountryUSA? Stateilst:Canadianprovincelist"></OmniSelect>
                </div>
                <div v-if="IsCountryUSA==false"  class="col-md-4" style="margin-top: 1px !important">
                    <label  for="zipcode" class="lbltxt">ZIP CODE</label>

                    <b-form-input autocomplete="off" id="zipcode" class="" v-model="IC.nonformatezipcode"  placeholder="Enter Zip Code"></b-form-input>
                </div>
                <div v-if="IsCountryUSA"  class="col-md-4" style="margin-top: 1px !important">
                    <label  for="zipcode" class="lbltxt">ZIP CODE</label>

                    <b-form-input autocomplete="off" id="zipcode" class=""  :minLength="5"  :maxLength="5"  v-bind:class="{
                errorborder: ( IsCountryUSA &&  (validationtextbox || !$v.IC.zipcode.numeric)) && $v.IC.zipcode.$dirty, noterrorborder: !IsCountryUSA && !validationtextbox && $v.IC.zipcode.numeric,
              }" v-model="IC.zipcode" @blur="SetDirty('zipcode')" @focus="ResetDirty('zipcode')" @keyup="ValidateZipCode()" placeholder="Enter Zip Code"></b-form-input>
                    <div v-show="IsCountryUSA && validationtextbox && $v.IC.zipcode.$dirty" class="error-message-format">
                        Input must be 5 characters
                    </div>
                    <div v-show="IsCountryUSA && !$v.IC.zipcode.numeric && $v.IC.zipcode.$dirty" class="error-message-format">
                        This field can only contain numerical values
                    </div>
                </div>
            </div>

            <div class="fieldborder" v-bind:class="{
            agencycontactwithtopmargin: IsCountryUSA,
            agencycontactwithnottopmargin: !IsCountryUSA,
          }" v-if="true">
                <label for="city" class="icagencycontant" v-if="true">INTERCOMPANY AGENCY CONTACT NAME</label>
                <b-form-input autocomplete="off" id="icagencycontact" class="omniipclass" v-if="true" v-model="IC.icagencycontant" placeholder="Enter InterCompany Agency Contact Name" :maxlength="99"></b-form-input>
            </div>

            <div class="fieldborder" v-if="true" style="margin-top: -4px">
                <label for="city" class="icagencycontantemail" v-if="true">INTERCOMPANY AGENCY CONTACT EMAIL</label>
                <b-form-input autocomplete="off" id="icagencycontactemail" class="omniipclass" v-if="true" v-model="IC.icagencycontactemail" :maxlength="100" placeholder="Enter InterCompany Agency Contact Email" @blur="SetDirty('icagencycontactemail')" @focus="ResetDirty('icagencycontactemail')" v-bind:class="{
              errorborder:
                !$v.IC.icagencycontactemail.email &&
                $v.IC.icagencycontactemail.$dirty,
              noterrorborder: $v.IC.icagencycontactemail.email,
            }"></b-form-input>
                <div class="error-message-format" v-if="
              !$v.IC.icagencycontactemail.email &&
              $v.IC.icagencycontactemail.$dirty
            ">
                    Please enter a valid email address (eg. test@finsys.com)
                </div>
            </div>
            <div class="fieldborder"  style="margin-top: -8px">
                <label for="Counterparty">Intercompany Agency Counterparty <span class="small">(only if different from HFM code being requested)</span>.</label>
                <b-form-input autocomplete="off" id="billingCity" class="omniipclass"  v-model="IC.CounterParty" placeholder="Intercompany Agency Counterparty"></b-form-input>
            </div>
            <div class="fieldborder" style="margin-top: -4px">
                <label for="axlocatoin" class="lbltxt">Are there anything additional instructions?</label>
                <b-form-textarea id="textarea" placeholder="IE: Existing Customer, Main account rollup, etc." v-model="IC.additionalinstructions" rows="8" max-rows="8"></b-form-textarea>
            </div>

            <div class="fieldborder" style="margin-top: -4px !important">
                <label for="city" class="icagencycontantemail" v-if="true">Attach screenshot from Omnicom Intercompany Locator</label>
                <br />
                <a target="_blank" href="https://home.omnicomlink.com/fa/fr/Pages/IntercompanyLocator.aspx" class="OMCLocator">Omnicom Intercompany Locator</a>
                <vue-dropzone v-model="IC.hfmfiles" :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedtoDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone" v-on:vdropzone-success="addAnalysisId" v-on:vdropzone-error="Uploaderror" v-on:vdropzone-sending="sendingEvent">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img style="margin-top: -7px !important; font-family: 'Open Sans'" />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>
                        </p>
                    </div>
                </vue-dropzone>
            </div>
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>

    <div class="form-group pt-60 text-right footer-buttons">
        <button class="btn btn-link text-uppercase" @click="Cancel()">
            CANCEL
        </button>
        <button v-if="this.requestid == null || this.isDraft == true" class="text-uppercase btn btn-secondary btn-primary" @click="SaveInterCompanyasDraft()">
            SAVE FOR LATER
        </button>

        <button v-if="this.requestid == null || this.isDraft == true" class="text-uppercase btn custom-button btn-primary" :disabled="this.$v.$invalid || forminvalid() || IsCodeExist" @click="AddInterCompany()">
            SUBMIT
        </button>
        <button v-if="this.requestid > 0 && this.isDraft != true" class="text-uppercase btn custom-button btn-primary" :disabled="this.$v.$invalid || forminvalid() || IsCodeExist" @click="UpdateInterCompany()">
            RESUBMIT
        </button>
        <!-- <button v-if="this.requestid==null || this.isDraft==true"
        class="text-uppercase btn custom-button btn-primary"
        @click="SaveInterCompanyasDraft()"
      >
        SAVE FOR LATER
      </button> -->
    </div>
</div>
</template>

<style scoped>
.agencycontactwithtopmargin {
    margin-top: -30px !important;
}

.agencycontactwithnottopmargin {
    margin-top: -4px !important;
}

.withtopmargin {
    margin-top: -22px !important;
}

.withnottopmargin {
    margin-top: -4px !important;
}

.OMCLocator {
    width: 498px;
    margin-left: -2px;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    margin-bottom: 6px;
    padding-left: 12px;
    color: #00a1d2;
    text-transform: uppercase;
}

.redtextbox {
    width: 415px !important;
    height: 35px !important;
    border: 1px solid #df5678 !important;
}

.notredtextbox {
    border: none !important;
}

.fieldborder {
    padding-bottom: 15px !important;
}

.customdropzone {
    height: 44px !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 387px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    /* color: #a2a9ad !important; */
}

.zipcodevalidationmsg {
    /* margin-top: -20px !important; */
    width: 157px !important;
    font-size: 10px !important;
    color: #df5678 !important;
    font-family: "Open Sans" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: italic !important;
    line-height: 0.8 !important;
    letter-spacing: normal !important;
    text-align: left !important;
}

.error-message1 {
    height: 14px !important;
    font-size: 10px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.error-message {
    height: 14px !important;
    font-size: 10px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.draganddropspan {
    color: #00a1d2;
}
</style>

<script>
import {
    required,
    requiredIf,
    maxLength,
    minLength,
    email,
    numeric,
} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import service from "../services/Dropdowns";
import store from "@/store";
// import func from '../../vue-temp/vue-editor-bridge';
// import func from '../../vue-temp/vue-editor-bridge';

export default {
    validationtextbox: false,
    disableSubmit: true,
    validations: {
        IC: {
            hfmcode: {
                required,
                maxLength: maxLength(15),
            },
            icagencyname: {
                required,
                maxLength: maxLength(100),
            },
            agencynwregion: {
                required,
            },
            country: {
                required,
            },
            billingstreetaddressline1: {
                required,
            },
            // billingstreetaddressline2: {
            //     required: requiredIf(function () {
            //         return this.IsCountryUSA == false;
            //     }),
            // },
            // streetaddress: {
            //     required: requiredIf(function () {
            //         return this.IsCountryUSA == true;
            //     }),
            // },
            city: {
               required,
            },
            state: {
                required: requiredIf(function () {
                    return this.IsCountryUSA == true || this.IsCountryCanada==true;
                }),
            },
            nonformatezipcode:{
                required : requiredIf(function(){
                     return this.IsCountryUSA == false;
                }),
            },
            zipcode: {
                required : requiredIf(function(){
                    return this.IsCountryUSA == true;
                }),
                numeric,
                minLength:minLength(5),
                maxLength:maxLength(5),
            },
            icagencycontant: {
                required,
                maxLength: maxLength(99),
            },
            icagencycontactemail: {
                required,
                maxLength: maxLength(100),
                email,
            },
            additionalinstructions: {
                required,
            },
        },
    },

    data() {
        return {
            isDraftUserRequest:false,
            isdestroying: false,
            FileExist: false,
            ICRequestId: 0,
            IsCodeExist: false,
            validationtextbox: false,
            ddl: 0,
            NetworkRegion: [],
            FilesUploaded: [],
            CurrentFilesList: [],
            RemoveFileList: [],
            dropzoneOptions: {
                closeFilterIcon: require("@/assets/Icons/file.png"),
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/InterCompany/ICUpload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                init: function () {
                    // this.on("maxfilesexceeded", function (file) {
                    //   this.removeFile(file);
                    //   alert("Maximum File Upload limit is 5!");
                    // });
                    //this.alreadycountvalidated=false;
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            // this.a=0;
                            // this.a=this.a+1;
                            // window.alert(this.alreadycountvalidated);
                            // if(this.a>0 && !this.alreadycountvalidated)
                            // {
                            window.alert("Maximum File Upload limit is 5!");
                            //this.alreadycountvalidated==true;
                            //}
                        }
                    });
                },
            },
            InterAgencyCountryList: [],

            Stateilst: [],
            Canadianprovincelist:[
                {
                    value:"Alberta",
                    label:"Al-Alberta"
                },
                {
                    value:"British Columbia",
                    label:"BC-British Columbia"
                },
                {
                    value:"Manitoba",
                    label:"MB-Manitoba"
                },
                {
                    value:"New Brunswick",
                    label:"NB-New Brunswick"
                },
                {
                    value:"Newfoundland and Labrador",
                    label:"NF-Newfoundland and Labrador"
                },
                {
                    value:"Northwest Territories",
                    label:"NT-Northwest Territories"
                },
                {
                    value:"Nova Scotia",
                    label:"NS-Nova Scotia"
                },
                {
                    value:"Nunavut",
                    label:"NU-Nunavut"
                },
                {
                    value:"Ontario",
                    label:"OT-Ontario"
                },
                {
                    value:"Prince Edward Island",
                    label:"PE-Prince Edward Island"
                },
                {
                    value:"Quebec",
                    label:"QB-Quebec"
                },
                {
                    value:"Saskatchewan",
                    label:"SC-Saskatchewan"
                },
                {
                    value:"Yukon",
                    label:"YK-Yukon"
                },
            ],

            IC: {
                hfmcode: null,
                icagencyname: null,
                hfmfiles: null,
                agencynwregion: null,
                country: null,
                billingstreetaddressline1: null,
                billingstreetaddressline2: null,
                streetaddress: null,
                city: null,
                state: null,
                zipcode: null,
                nonformatezipcode:null,
                icagencycontant: null,
                icagencycontactemail: null,
                additionalinstructions: null,
                ID: 0,
                Requestor: null,
                omnniflowprocessId: 0,
                omniflowprojectId: 0,
                CounterParty: null,
            },
        };
    },

    components: {
        vueDropzone: vue2Dropzone,
    },

    mounted() {
        this.LoadNetworkRegion();
        this.LoadDropdownValues();
        this.LoadStates();

        if (this.requestid > 0) {
            if (this.isDraft != true) {
                this.GetICRequestWithTaskDetails();
            } else {
                this.GetICRequestDraftDetails();
            }
        }
    },

    props: {
        requestid: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false,
        },
    },

    beforeDestroy() {
        this.isdestroying = true;
    },

    methods: {
        Countrychange(){
                this.IC.billingstreetaddressline1 = null;
                this.IC.billingstreetaddressline2 = null;
                this.IC.streetaddress = null;
                this.IC.zipcode = null;
                this.IC.nonformatezipcode=null;
                this.IC.city = null;
                this.IC.state = null;
        },
        SetDirty(property) {
            this.$v.IC[property].$touch();
        },
        ResetDirty(property) {
            this.$v.IC[property].$reset();
        },

        RemovedFromDropzone(file) {
            if (this.isdestroying != true) {
                this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
                this.RemoveFileList.push(file.name);
                let fileIndex = this.CurrentFilesList.indexOf(file.name);
                if (fileIndex != -1) {
                    this.CurrentFilesList.splice(fileIndex, 1);
                }
                window.console.log(JSON.stringify(this.RemoveFileList));
            }
        },

        sendingEvent(file, xhr, formData) {
            formData.append("ICfileuploadId", this.ICRequestId);
            this.$refs.myVueDropzone.dropzone.files.forEach((value) => {
                this.CurrentFilesList.push(value.name);
            });
            formData.append("filenames", this.CurrentFilesList);
        },

        Uploaderror(result) {
            window.console.log(result);
            store.commit("progress/removeProcessing", {
                root: true,
            });
        },

        addAnalysisId(result) {

            if (result.status == "success") {
                store.commit("progress/removeProcessing", {
                    root: true,
                });
                window.console.log(`entered in if condition is true`);
                if(this.isDraftUserRequest==false)
                {
                     store.dispatch(
                    "progress/setSuccessMessage",
                    `The InterCompany request has been submitted successfully`
                );
                }
                else
                {
                     store.dispatch(
                    "progress/setSuccessMessage",
                    `The Intercompany request has been saved successfully as draft`
                );
                }

                window.console.log(`entered before router push`);

                this.$router.push("/IntercompanyAgencies");
            } else {
                window.console.log("error while uploading files");
            }
        },

        CheckHFMCodeExist() {
            var inputObj = {
                HFMCode: this.IC.hfmcode.toUpperCase(),
                referenceCode: "19ab7a9e-b36f-4bb3-80dc-63b52f8ad39c",
            };
            service
                .getCheckHfmCodeExist(inputObj)
                .then((res) => {
                    this.IsCodeExist = res.data;
                })
                .catch(() => {
                    this.IsCodeExist = false;
                });
        },

        async GetICRequestWithTaskDetails() {
            this.$store
                .dispatch("client/GetCompleteICRequest", parseInt(this.requestid))
                .then((res) => {
                    window.console.log(JSON.stringify(res.data));
                    this.IC.hfmcode = res.data.ExpectedHFMCode;
                    this.IC.icagencyname = res.data.AgencyName;
                    this.IC.agencynwregion =
                        res.data.AgencyNetworkRegionCode != null ?
                        res.data.AgencyNetworkRegionCode :
                        res.data.AgencyNetworkRegion;
                    this.IC.country = res.data.AgencyCountry;
                    this.IC.billingstreetaddressline1 = res.data.AgencyStreetAddressLine1;
                    this.IC.billingstreetaddressline2 = res.data.AgencyStreetAddressLine2;
                    this.IC.city = res.data.AgencyCity;
                    this.IC.state = res.data.AgencyState;
                    if(this.IsCountryUSA)
                    {
                        this.IC.zipcode = res.data.AgencyZipCode;
                    }
                    else
                    {
                        this.IC.nonformatezipcode= res.data.AgencyZipCode;
                    }
                    this.IC.icagencycontant = res.data.AgencyContact;
                    this.IC.icagencycontactemail = res.data.AgencyContactEmail;
                    this.IC.ID = res.data.ID;
                    this.IC.Requestor = res.data.Requestor;
                    this.IC.additionalinstructions = res.data.AdditionalInstructions;
                    this.IC.omnniflowprocessId = res.data.OmniflowProcessId;
                    this.IC.omniflowprojectId = res.data.OmniflowProjectId;
                    this.IC.CounterParty = res.data.CounterParty;
                    if (res.data.Files.length > 0) {
                        this.ManuallyUploadFiles(res.data.Files);
                        this.ddl = res.data.Files.length;
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$router.push({
                            name: "error",
                            params: {
                                message: "UnAuthorized"
                            },
                        });
                    }
                    else {
                        this.Error = true;
                        alert(err);
                    }
                });
        },
        async GetICRequestDraftDetails() {
            this.$store
                .dispatch(
                    "intercompany/getIntercompanyDraftData",
                    parseInt(this.requestid)
                )
                .then((res) => {
                    this.IC.hfmcode = res.data.ExpectedHFMCode;
                    this.IC.icagencyname = res.data.AgencyName;
                    this.IC.agencynwregion =
                        res.data.AgencyNetworkRegionCode != null ?
                        res.data.AgencyNetworkRegionCode :
                        res.data.AgencyNetworkRegion;
                    this.IC.country = res.data.AgencyCountry;
                    this.IC.billingstreetaddressline1 = res.data.AgencyStreetAddressLine1;
                    this.IC.billingstreetaddressline2 = res.data.AgencyStreetAddressLine2;
                    this.IC.streetaddress = res.data.AgencyStreetAddress;
                    this.IC.city = res.data.AgencyCity;
                    this.IC.state = res.data.AgencyState;
                    if(this.IsCountryUSA) {
                        this.IC.zipcode = res.data.AgencyZipCode;
                    } else {
                        this.IC.nonformatezipcode= res.data.AgencyZipCode;
                    }
                    this.IC.icagencycontant = res.data.AgencyContact;
                    this.IC.icagencycontactemail = res.data.AgencyContactEmail;
                    this.IC.ID = res.data.ID;
                    this.IC.Requestor = res.data.Requestor;
                    this.IC.additionalinstructions = res.data.AdditionalInstructions;
                    this.IC.omnniflowprocessId = res.data.OmniflowProcessId;
                    this.IC.omniflowprojectId = res.data.OmniflowProjectId;
                    this.IC.CounterParty = res.data.CounterParty;
                    if (res.data.Files && res.data.Files.length > 0) {
                        this.ManuallyUploadFiles(res.data.Files);
                        this.ddl = res.data.Files.length;
                    }
                    if (this.IC.hfmcode != null && this.IC.hfmcode.length > 0) {
                        this.CheckHFMCodeExist();
                    }
                })
                .catch((err) => {
                    this.Error = true;
                    alert(err);
                });
        },

        ManuallyUploadFiles(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.FilesUploaded.push(fileUploaded.Name);
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    this.CurrentFilesList.push(fileUploaded.Name);
                });
            }
        },

        LoadDropdownValues() {
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.InterAgencyCountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.InterAgencyCountryList.findIndex(
                            (i) => i.label == "USA - United States"
                        );
                        let indexCanada = this.InterAgencyCountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.InterAgencyCountryList.unshift(
                                this.InterAgencyCountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.InterAgencyCountryList.unshift(
                                this.InterAgencyCountryList.splice(indexUSA, 1)[0]
                            );
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },

        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.Stateilst = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },

        ValidateZipCode() {
            if (this.IsCountryUSA && this.IC.zipcode.length != 0 && this.IC.zipcode.length < 5) {
                this.validationtextbox = true;
            } else {
                this.validationtextbox = false;
            }
        },

        AddInterCompany() {
            window.console.log(
                `Get queued files ${this.$refs.myVueDropzone.dropzone.getQueuedFiles()}`
            );
            window.console.log(
                `Get queued files ${
          this.$refs.myVueDropzone.dropzone.getQueuedFiles().length
        }`
            );
            let ICForm = {};
            ICForm.ExpectedHFMCode = this.IC.hfmcode;
            ICForm.AgencyName = this.IC.icagencyname;
            ICForm.AgencyNetworkRegion = this.IC.agencynwregion;
            ICForm.AgencyCountry = this.IC.country;
            ICForm.AgencyStreetAddressLine1 = this.IC.billingstreetaddressline1;
            ICForm.AgencyStreetAddressLine2 = this.IC.billingstreetaddressline2;
            ICForm.AgencyStreetAddress = this.IC.streetaddress;
            ICForm.AgencyCity = this.IC.city;
            ICForm.AgencyState = this.IC.state;
            ICForm.AgencyZipCode = this.IsCountryUSA?  this.IC.zipcode : this.IC.nonformatezipcode;
            ICForm.AgencyContact = this.IC.icagencycontant;
            ICForm.AgencyContactEmail = this.IC.icagencycontactemail;
            ICForm.ID = 0;
            ICForm.Requestor = "";
            ICForm.CreatedDate = new Date();
            ICForm.UpdatedDate = new Date();
            ICForm.CompletedHFMCode = "";
            ICForm.OmniflowProcessId = 0;
            ICForm.OmniflowProjectId = 0;
            ICForm.AdditionalInstructions = this.IC.additionalinstructions;
            ICForm.CounterParty = this.IC.CounterParty;
            ICForm.Files = this.CurrentFilesList.map(function (value) {
                return {
                    Name: value
                };
            });
            ICForm.DraftRequestID =
                this.isDraft == true ? parseInt(this.requestid) : 0;
            this.$store
                .dispatch("client/AddIC", ICForm)
                .then((res) => {
                    window.console.log(`Result submit ${JSON.stringify(res.data)}`);
                    if (res.data.message != null) {
                        window.console.log(
                            `Get queued files ${this.$refs.myVueDropzone.dropzone.getQueuedFiles()}`
                        );
                        window.console.log(
                            `Get queued files ${
                this.$refs.myVueDropzone.dropzone.getQueuedFiles().length
              }`
                        );

                        this.ICRequestId = res.data.message;
                        if (
                            this.$refs.myVueDropzone.dropzone.files.length > 0 &&
                            this.$refs.myVueDropzone.dropzone.getQueuedFiles().length > 0
                        ) {
                            this.isDraftUserRequest=false;
                            store.commit("progress/setProcessing", {
                                root: true,
                            });
                            this.$refs.myVueDropzone.dropzone.processQueue();
                            window.console.log(`File process completed`);
                        } else {
                            this.$router.push("/IntercompanyAgencies");
                        }
                    }
                })
                .catch((ex) => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Adding InterCompany. ${ex}`
                    );
                });
        },

        SaveInterCompanyasDraft() {
            let ICForm = {};
            ICForm.ExpectedHFMCode = this.IC.hfmcode;
            ICForm.AgencyName = this.IC.icagencyname;
            ICForm.AgencyNetworkRegion = this.IC.agencynwregion;
            ICForm.AgencyCountry = this.IC.country;
            ICForm.AgencyStreetAddressLine1 = this.IC.billingstreetaddressline1;
            ICForm.AgencyStreetAddressLine2 = this.IC.billingstreetaddressline2;
            ICForm.AgencyStreetAddress = this.IC.streetaddress;
            ICForm.AgencyCity = this.IC.city;
            ICForm.AgencyState = this.IC.state;
            ICForm.AgencyZipCode = this.IsCountryUSA?  this.IC.zipcode : this.IC.nonformatezipcode;
            ICForm.AgencyContact = this.IC.icagencycontant;
            ICForm.AgencyContactEmail = this.IC.icagencycontactemail;
            ICForm.ID = 0;
            ICForm.Requestor = "";
            ICForm.CreatedDate = new Date();
            ICForm.UpdatedDate = new Date();
            ICForm.CompletedHFMCode = "";
            ICForm.OmniflowProcessId = 0;
            ICForm.OmniflowProjectId = 0;
            ICForm.AdditionalInstructions = this.IC.additionalinstructions;
            ICForm.CounterParty = this.IC.CounterParty;
            ICForm.Files = this.CurrentFilesList.map(function (value) {
                return {
                    Name: value
                };
            });
            ICForm.DraftRequestID =
                this.isDraft == true ? parseInt(this.requestid) : 0;
            this.$store
                .dispatch("client/SaveInterCompanyDraft", ICForm)
                .then((res) => {
                    if (res.data.message != null) {
                        this.ICRequestId = res.data.message;
                        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
                            this.isDraftUserRequest=true;
                            store.commit("progress/setProcessing", {
                                root: true,
                            });
                            this.constructurl(true);
                            this.$refs.myVueDropzone.dropzone.processQueue();
                        } else {
                            store.dispatch(
                                "progress/setSuccessMessage",
                                `The Intercompany request has been saved successfully as draft`
                            );

                            this.$router.push("/IntercompanyAgencies");
                        }

                        // store.dispatch(
                        //   "progress/setSuccessMessage",
                        //   `The InterCompany request has been saved as Draft successfully`
                        // );

                        //this.$router.push("/");
                    } else {
                        window.console.log("error while uploading files");
                    }
                })
                .catch((ex) => {
                    this.constructurl(false);
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Saving InterCompany Request as Draft. ${ex}`
                    );
                });
        },

        UpdateInterCompany() {
            let ICForm = {};
            ICForm.ExpectedHFMCode = this.IC.hfmcode;
            ICForm.AgencyName = this.IC.icagencyname;
            ICForm.AgencyNetworkRegion = this.IC.agencynwregion;
            ICForm.AgencyCountry = this.IC.country;
            ICForm.AgencyStreetAddressLine1 = this.IC.billingstreetaddressline1;
            ICForm.AgencyStreetAddressLine2 = this.IC.billingstreetaddressline2;
            ICForm.AgencyStreetAddress = this.IC.streetaddress;
            ICForm.AgencyCity = this.IC.city;
            ICForm.AgencyState = this.IC.state;
            ICForm.AgencyZipCode = this.IsCountryUSA?  this.IC.zipcode : this.IC.nonformatezipcode;
            ICForm.AgencyContact = this.IC.icagencycontant;
            ICForm.AgencyContactEmail = this.IC.icagencycontactemail;
            ICForm.ID = parseInt(this.requestid);
            ICForm.Requestor = "test";
            ICForm.CreatedDate = new Date();
            ICForm.UpdatedDate = new Date();
            ICForm.CompletedHFMCode = "";
            ICForm.OmniflowProcessId = this.IC.omnniflowprocessId;
            ICForm.OmniflowProjectId = this.IC.OmniflowProjectId;
            ICForm.AdditionalInstructions = this.IC.additionalinstructions;
            ICForm.CounterParty = this.IC.CounterParty;
            this.$store
                .dispatch("client/UpdateIC", ICForm)
                .then(() => {
                    store.commit("progress/setProcessing", {
                        root: true,
                    });
                    if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
                        this.ICRequestId = parseInt(this.requestid);
                        this.$refs.myVueDropzone.dropzone.processQueue();
                    } else {
                        if (this.RemoveFileList.length > 0) {
                            var inputObj = {
                                name: this.RemoveFileList,
                                requestid: parseInt(this.requestid),
                            };
                            service.RemoveFileFromDirectory(inputObj);
                        }
                        store.commit("progress/removeProcessing", {
                            root: true,
                        });
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `The InterCompany request has been submitted successfully`
                        );
                        this.$router.push("/IntercompanyAgencies");
                    }
                })
                .catch((ex) => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Adding InterCompany. ${ex}`
                    );
                    this.RemoveFileList = [];
                });
        },

        Cancel() {
            this.RemoveFileList = [];
            this.$router.push("/IntercompanyAgencies");
        },

        UploadedtoDropzone() {
            this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
        },

        manuallydropedfiles() {
            this.FileExist = true;
        },

        forminvalid() {
            if (this.ddl > 0) {
                return false;
            } else {
                return true;
            }
        },

        // zipvalidation(){
        //     if(this.IsCountryUSA)
        //     {
        //         return this.validationtextbox;
        //     }
        //     else
        //     {
        //         return this.validationtextbox;
        //     }
        // },

        constructurl(isdraftrequest) {
            if (isdraftrequest) {
                this.$refs.myVueDropzone.setOption(
                    "url",
                    `${process.env.VUE_APP_API_ROOT}api/v1/InterCompany/ICDraftUpload`
                );
            } else {
                this.$refs.myVueDropzone.setOption(
                    "url",
                    `${process.env.VUE_APP_API_ROOT}api/v1/InterCompany/ICUpload`
                );
            }
        },

        async LoadNetworkRegion() {
            service
                .getAgencyNetworkRegions("Agency Network")
                .then((res) => {
                    this.NetworkRegion = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },

        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
    },

    computed: {
        IsCountryUSA() {
            return (
                this.IC.country != null &&
                this.IC.country == "78d70193e1eb493dafcf3f261b7759ef"
            );
        },
        IsCountryCanada() {
            return (
                this.IC.country != null &&
                this.IC.country == "88180661ecff4e65bbc3acf6121f1fb9"
            );
        }
    },

    watch: {
        "IC.hfmfiles": function (val) {
            if (val.length > 0) {
                window.console.log("Exist");
            } else {
                window.console.log("Not Exist");
            }
        },
        // "IC.country": function () {
        //     if (this.IsCountryUSA == true) {
        //         this.IC.billingstreetaddressline1 = null;
        //         this.IC.billingstreetaddressline2 = null;
        //         this.IC.streetaddress = null;
        //         this.IC.zipcode = null;
        //         this.IC.nonformatezipcode=null;
        //         this.IC.city = null;
        //         this.IC.state = null;
        //     } else {
        //         this.IC.streetaddress = null;
        //         this.IC.zipcode = null;
        //         this.IC.city = null;
        //         this.IC.state = null;
        //         this.IC.nonformatezipcode=null;
        //         this.IC.billingstreetaddressline1=null;
        //         this.IC.billingstreetaddressline2=null;
        //     }
        //},
    },
};
</script>
